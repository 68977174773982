import React from "react"
import { PrismicLink } from "@prismicio/react"

const LAYOUT_WITH_SIDEBAR = "With Sidebar"

const PageNav = ({ layout, items }) => {
  if (!items || !items.length) return null

  if (layout === LAYOUT_WITH_SIDEBAR) {
    return (
      <ul className="space-y-0.5 text-cloud-white md:space-y-2">
        {items.map(({ title, link }) => {
          if (!title || !link) return null

          return (
            <li key={title}>
              <PrismicLink
                className="font-bold uppercase tracking-wide hover:text-night-black focus:text-night-black md:text-2xl lg:text-3xl"
                field={link}
                activeClassName="text-night-black"
              >
                {title}
              </PrismicLink>
            </li>
          )
        })}
      </ul>
    )
  } else {
    return (
      <div className="bg-night-black py-4 px-gutter text-cloud-white">
        <ul className="space-y-0.5 md:flex md:space-y-0 md:space-x-5 lg:space-x-7">
          {items.map(({ title, link }) => {
            if (!title || !link) return null

            return (
              <li>
                <PrismicLink
                  className="font-bold uppercase tracking-wide hover:text-sky-blue focus:text-sky-blue lg:text-lg"
                  field={link}
                  activeClassName="text-sky-blue"
                >
                  {title}
                </PrismicLink>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default PageNav
