import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText, PrismicLink } from "@prismicio/react"

import AccordionItem from "../AccordionItem/AccordionItem"

const ContentSection = ({
  content_section_is_visible,
  content_section,
  content_section_bg_colour,
  content_section_text_colour,
}) => {
  if (!content_section_is_visible) {
    return null
  }

  const bgColourMap = {
    "Land Brown": "bg-land-brown",
    "Sky Blue": "bg-sky-blue",
    "Sand Yellow": "bg-sand-yellow",
    "Sea Teal": "bg-sea-teal",
    "Coral Pink": "bg-coral-pink",
  }

  const textColourMap = {
    "Cloud White": "text-cloud-white",
    "Night Black": "text-night-black",
  }

  const content_section_bg_colour_class =
    bgColourMap[content_section_bg_colour] || "bg-sky-blue"

  const content_section_text_colour_class =
    textColourMap[content_section_text_colour] || "text-cloud-white"

  return (
    <section
      className={`section ${content_section_bg_colour_class} ${content_section_text_colour_class}`}
    >
      <div className="grid grid-cols-12 space-y-10 lg:gap-x-10">
        {content_section.map(slice => {
          const { slice_type, fields } = slice

          if (slice_type === "title") {
            const { title } = fields

            if (!title) return null

            return (
              <div className="col-start-1 col-end-11 lg:col-start-3 lg:col-end-9">
                <h2 className="max-w-sm text-3xl font-bold uppercase tracking-wide">
                  {title}
                </h2>
              </div>
            )
          }

          if (slice_type === "rich_text") {
            const { content } = fields

            return (
              <div className="col-start-1 col-end-11 lg:col-start-3 lg:col-end-9">
                <div className="rte">
                  <PrismicRichText field={content.richText} />
                </div>
              </div>
            )
          }

          if (slice_type === "button") {
            const { text, link } = fields

            if (!link || !text) return null

            return (
              <div className="col-start-1 col-end-11 lg:col-start-3 lg:col-end-9">
                <PrismicLink
                  className="button button--black"
                  field={link}
                  target="_blank"
                >
                  {text}
                </PrismicLink>
              </div>
            )
          }

          if (slice_type === "image") {
            const { image, caption } = fields

            if (!image) return null

            return (
              <div className="col-start-1 col-end-13 mt-12 lg:col-start-3 lg:col-end-10">
                <GatsbyImage
                  className="mt-4"
                  image={getImage(image.gatsbyImageData)}
                  alt={image.alt || ""}
                />
                {caption && <p className="mt-1 text-sm">{caption}</p>}
              </div>
            )
          }

          if (slice_type === "accordion") {
            const { items } = slice

            if (!items.length) return null

            return (
              <div className="col-start-1 col-end-11 lg:col-start-3 lg:col-end-9">
                <div className="space-y-4">
                  {items.map(item => {
                    const { title, content } = item

                    if (!title || !content) return null

                    return (
                      <AccordionItem>
                        <AccordionItem.Toggle
                          tag="h4"
                          className="cursor-pointer text-2xl font-semibold"
                        >
                          {title}
                        </AccordionItem.Toggle>
                        <AccordionItem.Content className="rte mt-6 mb-8">
                          <PrismicRichText field={content.richText} />
                        </AccordionItem.Content>
                      </AccordionItem>
                    )
                  })}
                </div>
              </div>
            )
          }

          return null
        })}
      </div>
    </section>
  )
}

export default ContentSection
