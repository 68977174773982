import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import PageHeader from "../components/Page/PageHeader"
import PageNav from "../components/Page/PageNav"
import ContentSection from "../components/ContentSection/ContentSection"
import FlexibleContent from "../components/FlexibleContent/FlexibleContent"

const LAYOUT_WITH_SIDEBAR = "With Sidebar"

const Page = ({ data }) => {
  const {
    header_image,
    enable_shadow,
    header_title,
    subheading,
    page_links,
    content_section_is_visible,
    content_section_bg_colour,
    content_section_text_colour,
    content_section,
    flexible_content,
    flexible_content_layout,
    footer,
    meta_title,
    meta_description,
  } = data.page.fields

  if (flexible_content_layout === LAYOUT_WITH_SIDEBAR) {
    return (
      <Layout>
        <Seo
          title={meta_title || header_title}
          description={meta_description}
        />
        <PageHeader
          title={header_title}
          subheading={subheading}
          image={header_image}
          shadow={enable_shadow}
        />
        <ContentSection
          content_section_is_visible={content_section_is_visible}
          content_section={content_section}
          content_section_bg_colour={content_section_bg_colour}
          content_section_text_colour={content_section_text_colour}
        />
        <div className="grid grid-cols-12 lg:gap-x-10">
          <div className="col-span-12 bg-coral-pink md:col-span-4">
            <div className="py-4 px-gutter md:py-16 lg:sticky lg:top-0 lg:left-0 lg:py-24">
              <PageNav layout={flexible_content_layout} items={page_links} />
            </div>
          </div>
          <div className="col-start-1 col-end-11 md:col-start-6">
            <div className="space-y-10 py-16 px-gutter md:px-0 lg:space-y-16 lg:py-24">
              {flexible_content &&
                flexible_content.map(slice => (
                  <FlexibleContent key={slice.id} {...slice} />
                ))}
            </div>
          </div>
        </div>
        {footer &&
          footer.map(slice => <FlexibleContent key={slice.id} {...slice} />)}
      </Layout>
    )
  } else {
    return (
      <Layout>
        <Seo
          title={meta_title || header_title}
          description={meta_description}
        />
        <PageHeader
          title={header_title}
          subheading={subheading}
          image={header_image}
          shadow={enable_shadow}
        />
        <PageNav items={page_links} />
        <ContentSection
          content_section_is_visible={content_section_is_visible}
          content_section={content_section}
          content_section_bg_colour={content_section_bg_colour}
          content_section_text_colour={content_section_text_colour}
        />
        {flexible_content &&
          flexible_content.map(slice => (
            <FlexibleContent key={slice.id} {...slice} />
          ))}
      </Layout>
    )
  }
}

export default Page

export const query = graphql`
  query PageQuery($uid: String) {
    page: prismicPages(uid: { eq: $uid }) {
      fields: data {
        header_image {
          gatsbyImageData
          alt
        }
        subheading
        header_title
        enable_shadow
        page_links {
          title
          link {
            ...linkFragment
          }
        }
        content_section_is_visible
        content_section_bg_colour
        content_section_text_colour
        content_section_bg_pattern
        content_section_bg_pattern_position
        content_section {
          ... on PrismicPagesDataContentSectionTitle {
            id
            slice_type
            fields: primary {
              title
            }
          }
          ... on PrismicPagesDataContentSectionRichText {
            id
            slice_type
            fields: primary {
              content {
                richText
              }
            }
          }
          ... on PrismicPagesDataContentSectionButton {
            id
            slice_type
            fields: primary {
              link {
                ...linkFragment
              }
              text
            }
          }
          ... on PrismicPagesDataContentSectionImage {
            id
            slice_type
            fields: primary {
              image {
                gatsbyImageData
                alt
              }
              caption
            }
          }
          ... on PrismicPagesDataContentSectionAccordion {
            id
            slice_type
            items {
              title
              content {
                richText
              }
            }
          }
        }
        flexible_content_layout
        flexible_content {
          ... on PrismicPagesDataFlexibleContentBrandsSection {
            id
            slice_type
            fields: primary {
              layout
              title
              content {
                richText
              }
              cta_text
              cta_link {
                ...linkFragment
              }
            }
            brands: items {
              brand {
                document {
                  ...brandsFragment
                }
              }
              image {
                gatsbyImageData
                alt
              }
            }
          }
          ... on PrismicPagesDataFlexibleContentCtaCardsSection {
            id
            slice_type
            cta_cards: items {
              cta_card {
                document {
                  ...ctaCardsFragment
                }
              }
            }
          }
          ... on PrismicPagesDataFlexibleContentCodeSection {
            id
            slice_type
            fields: primary {
              html
            }
          }
          ... on PrismicPagesDataFlexibleContentProfilesSection {
            id
            slice_type
            fields: primary {
              title
              content {
                richText
              }
            }
            profiles: items {
              profile {
                document {
                  ...profilesFragment
                }
              }
            }
          }
          ... on PrismicPagesDataFlexibleContentReportsSection {
            id
            slice_type
            fields: primary {
              title
            }
          }
          ... on PrismicPagesDataFlexibleContentSustainabilityReportsSection {
            id
            slice_type
            fields: primary {
              title
            }
          }
          ... on PrismicPagesDataFlexibleContentKeyDatesSection {
            id
            slice_type
            fields: primary {
              title
            }
          }
          ... on PrismicPagesDataFlexibleContentPresentationsSection {
            id
            slice_type
            fields: primary {
              title
            }
          }
          ... on PrismicPagesDataFlexibleContentAnnouncementsSection {
            id
            slice_type
            fields: primary {
              title
            }
          }
          ... on PrismicPagesDataFlexibleContentRichTextSection {
            id
            slice_type
            fields: primary {
              title
              content {
                richText
              }
            }
            items {
              file_title
              file_link {
                ...linkFragment
              }
            }
          }
          ... on PrismicPagesDataFlexibleContentEmbedsSection {
            id
            slice_type
            fields: primary {
              title
            }
            items {
              embed {
                html
              }
              cta_text
              cta_link {
                ...linkFragment
              }
            }
          }
          ... on PrismicPagesDataFlexibleContentAnnouncementsSummarySection {
            id
            slice_type
            fields: primary {
              title
            }
          }
          ... on PrismicPagesDataFlexibleContentSubscribeSection {
            id
            slice_type
            fields: primary {
              title
            }
          }
          ... on PrismicPagesDataFlexibleContentJobAdderSection {
            id
            slice_type
          }
        }
        footer {
          ... on PrismicPagesDataFooterCtaCardsSection {
            id
            slice_type
            cta_cards: items {
              cta_card {
                document {
                  ...ctaCardsFragment
                }
              }
            }
          }
        }
        meta_title
        meta_description
      }
    }
  }
`
