import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PageHeader = ({ image, title, subheading, shadow }) => {
  const sectionClass = `relative bg-land-brown ${
    subheading ? "h-auto md:h-75 xl:h-100" : "h-50 lg:h-75 xl:h-100"
  }`
  const titleClass =
    "max-w-sm text-4xl font-bold uppercase tracking-wide text-cloud-white lg:max-w-3xl lg:text-6xl xl:text-7xl"

  return (
    <section className={sectionClass}>
      {image && (
        <div className="relative h-full w-full">
          <h1
            className={`absolute bottom-0 left-0 z-20 m-gutter ${titleClass} ${
              title ? "md:hidden" : ""
            }`}
          >
            {title}
          </h1>
          <GatsbyImage
            className="z-0 h-50 w-full object-cover md:absolute md:h-full"
            image={getImage(image.gatsbyImageData)}
            alt={image.alt || ""}
          />
          {shadow && (
            <div className="absolute inset-0 z-10 md:bg-gradient-to-t md:from-black md:to-transparent md:opacity-40"></div>
          )}
        </div>
      )}
      {subheading ? (
        <div className="left-0 z-20 p-gutter text-cloud-white md:absolute md:bottom-0">
          <h1 className={`${titleClass} hidden md:block`}>{title}</h1>
          <div className="max-w-4xl md:pt-8">
            <p className="text-lg font-medium">{subheading}</p>
          </div>
        </div>
      ) : (
        title && (
          <div className="absolute bottom-0 left-0 m-gutter">
            <h1 className={titleClass}>{title}</h1>
          </div>
        )
      )}
    </section>
  )
}

export default PageHeader
